// components
import Seo from 'src/components/Seo';
// hooks
import useContactUsMetaData from 'src/cms/data/contactUs/useContactUsMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const contactUsMetaData = useContactUsMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={contactUsMetaData?.title}
      description={contactUsMetaData?.description}
      schema={contactUsMetaData?.schema}
    />
  );
};

export { default } from './ContactUs';
