import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
// sections
import Intro from './sections/Intro';
// hooks
import { PageProps } from 'gatsby';

const ContactUs: FC<PageProps> = () => {
  return (
    <PageLayout>
      <Intro />
    </PageLayout>
  );
};

export default ContactUs;
